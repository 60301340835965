import { useEffect } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AppContainer from "components/Layout/AppContainer";
import Pages from "pages";
import { Toaster } from "react-hot-toast";
import { queryClient } from "services/api/apiHelper";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "styles/theme";
import "styles/globals.css";
import { getPerformance } from "firebase/performance";
import { analyticsApp } from "utils/firebase";
import "./libs/i18n";

const RenderDevTool = () => {
  if (process.env.NODE_ENV === "development") {
    return <ReactQueryDevtools initialIsOpen={false} />;
  }
  return null;
};

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      getPerformance(analyticsApp);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Toaster position="bottom-center" />
        <AppContainer>
          <Pages />
        </AppContainer>
        <RenderDevTool />
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
