import { ReactNode } from "react";
import { Box, Center, Container, Image } from "@chakra-ui/react";
import useGlobalStore from "app/store";

type AppContainerProps = {
  children: ReactNode,
}

export default function AppContainer({ children }: AppContainerProps) {
  const logoUrl = useGlobalStore((state) => state.customization?.logoUrl);
  return (
    <Container minW="65%" h="full">
      <Center py="8">
        {
          logoUrl && <Image src={logoUrl} width="32" />
        }
      </Center>
      <Box>
        {children}
      </Box>
    </Container>
  );
}