export const QKEYS = {
  HOUSING_SITUATION: "HOUSING_SITUATION",
  PERIOD_USING_CELLPHONE: "PERIOD_USING_CELLPHONE",
  REASONS_FOR_LOAN: "REASONS_FOR_LOAN",
  AUTHORIZE: "AUTHORIZE",
  INDEXED_BORROWER: "INDEXED_BORROWER",
  COUNTRY_STATES: "COUNTRY_STATES",
  DRAFT_APPLICATION: "DRAFT_APPLICATION",
};

export const GLOBAL_STORE = "oze-loans";

export const pages = {
  HOME: "/",
  APPLY: "/apply",
  REVIEW: "/apply/review",
  LOAN_FORM: "/form",
};

export const STATUS = {
  BORROWER_ACCEPTED: "BORROWER_ACCEPTED",
  BORROWER_DECLINED: "BORROWER_DECLINED",
  BORROWER_SUBMITTED: "BORROWER_SUBMITTED",
};

export const currencyToCountryId = {
  GHS: 83,
  NGN: 161,
};

export const LANG_KEY = "i18nextLng";
