import { extendTheme } from "@chakra-ui/react";
import { ButtonStyles as Button } from "./components/ButtonStyles";

// custom themes in chakra UI
// https://chakra-ui.com/docs/theming/customize-theme
// https://www.easyreact.com/articles/chakra-ui-customisations

const myTheme = extendTheme({
  fonts: {
    heading: "DM Sans",
    body: "DM Sans",
  },
  colors: {
    accent: "#1246EA",
    primary: "#161A5C",
    form_label: "#4D4D4D",
    secondary: "#5C5F8D",
    danger: "#D85A5A",
  },
  components: {
    Button,
  },
});

export default myTheme;
