// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/components/input.ts
export const ButtonStyles = {
  // style object for base or default style
  baseStyle: {
    outline: "none",
    _focus: { boxShadow: "none" },
    _disabled: {
      bg: "#CCCCCC",
      color: "#808080",
      border: "1px solid #CCCCCC",
      fontWeight: "400",
      opacity: 1,
      pointerEvents: "none",
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: () => ({
      bg: "#1246EA",
      borderRadius: "8px",
      fontSize: "14px",
      border: "1px solid #1246EA",
      color: "#FFFFFF",
      fontWeight: "500",
      _hover: {
        bg: "#597EF0",
        boxShadow: "md",
        color: "#fff",
        border: "1px solid #597EF0",
      },
      _active: {
        bg: "#1246EA",
      },
    }),
    secondaryOutline: () => ({
      bg: "white",
      borderRadius: "8px",
      fontSize: "14px",
      border: "1px solid #1246EA",
      color: "#1246EA",
      fontWeight: "500",
      _hover: {
        bg: "#597EF0",
        boxShadow: "md",
        color: "#fff",
        border: "1px solid #597EF0",
      },
      _active: {
        bg: "#1246EA",
      },
    }),
  },
  // default values for `size` and `variant`
  defaultProps: {
    variant: "primary",
  },
};
