import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { LoanRoutes } from "./routes";

const AppWrapper = () => useRoutes(LoanRoutes);

export default function index() {
  return (
    <Router>
      <AppWrapper />
    </Router>
  );
}