import axios from "axios";
import version from "utils/app-version.json";
import { LANG_KEY } from "utils/constants";

export const baseURL = process.env.REACT_APP_ENDPOINT_URL!;
export const v6URL = process.env.REACT_APP_ENDPOINT_URL!;

const userAgent = typeof window !== "undefined" && navigator?.userAgent;

export const headers = {
  "Content-Type": "application/json",
  "X-Lang": localStorage.getItem(LANG_KEY) || "en",
  "X-User-Agent": `${userAgent} - Oze Web App;Oze Loan Form: v${version.latest}`,
};

const instance = axios.create({
  baseURL,
  headers,
});

export default instance;
