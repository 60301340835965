import {
  QueryClient,
  QueryKey,
  useMutation,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import instance from "api/apiInstance";
import { AxiosRequestConfig } from "axios";
import i18next from "i18next";
import { toast } from "react-hot-toast";

export type ErrorType = {
  response: {
    data: {
      status: string;
      data: any;
      message: string;
    };
  };
};

type QueryOptions = Omit<UseQueryOptions<any, unknown, any, QueryKey>, "queryKey" | "queryFn">;

export const useQueryWrapper = (
  key: QueryKey,
  url: string,
  options?: QueryOptions,
  axiosOptions?: AxiosRequestConfig,
) => {
  const getRequest = async () => {
    const {
      data: { data },
    } = await instance.get(url, axiosOptions);
    return data;
  };
  return useQuery(key, getRequest, options);
};

export const getRequest = async (url: string, config?: AxiosRequestConfig) => {
  let data;

  await instance
    .get(url, config)
    .then((response) => {
      data = response?.data;
    })
    .catch((error) => {
      console.error(error);
    });

  return { data };
};

export const postRequest = async ({ url, data, config }: any) => {
  const response = await instance.post(url, data, config);
  return response?.data || response;
};

export const useMutationWrapper = (makeAPICall: any, onSuccess?: any, onError?: any): any => {
  return useMutation(makeAPICall, {
    onSuccess: ({ data }) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },

    onError: (error: ErrorType) => {
      if (onError) {
        onError(error);
      } else {
        const err = error as Record<any, any>;
        const message: any = err?.response?.data?.message;
        if (Array.isArray(message)) {
          message.map((errorMsg) => toast.error(`${errorMsg || i18next.t("anErrorOccured")}`));
        } else {
          toast.error(`${message || i18next.t("anErrorOccured")}`);
        }
      }
    },
  });
};

export const putRequest = async ({ url, data, config }: any) => {
  const response = await instance.put(url, data, config);
  return response?.data || response;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 600, // this is in millisecond
      retry: 0,
    },
  },
});
