import { Center, Container, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();
  return (
    <Container>
      <Center>
        <Text textAlign="center" fontWeight="900" color="primary" fontSize="4xl">
          {t("welcomeToOzeLoans")} 👋🏼
        </Text>
      </Center>
    </Container>
  );
}
