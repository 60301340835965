import { Suspense } from "react";
import Loader from "./Loader";

const WithSuspense = (Component) => (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
};
export default WithSuspense;
