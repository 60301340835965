import create from "zustand";
import { GLOBAL_STORE } from "utils/constants";
import { persist } from "zustand/middleware";

type LoanCurrencyType = "NGN" | "GHS";
interface GlobalStoreInterface {
  customization: {
    name: string;
    alias: string;
    hosts: string;
    currency: string;
    logoUrl: string;
  };
  setCustomization: (customization: any) => void;
  authToken: string;
  setAuthToken: (authToken: string) => void;
  loanApplication: Record<any, any>;
  setLoanApplication: (loanDetails: any) => void;
  loanReferenceId: string;
  setLoanReferenceId: (loanReferenceId: any) => void;
  indexedBorrower: any;
  setIndexedBorrower: (indexedBorrower: any) => void;
  loanCurrency: LoanCurrencyType;
  setLoanCurrency: (loanCurrency: string) => void;
}

const globalStore = (set: any) => ({
  customization: null,
  setCustomization: (customization: any) => {
    set({ customization });
  },
  authToken: "",
  setAuthToken: (authToken: any) => {
    set({ authToken });
  },
  loanApplication: null,
  setLoanApplication: (loanApplication: any) => {
    set({ loanApplication });
  },
  loanReferenceId: "",
  setLoanReferenceId: (loanReferenceId: string) => {
    set({ loanReferenceId });
  },
  indexedBorrower: null,
  setIndexedBorrower: (indexedBorrower: any) => {
    set({ indexedBorrower });
  },
  loanCurrency: "NGN",
  setLoanCurrency: (loanCurrency: LoanCurrencyType) => {
    set({ loanCurrency });
  },
});

const persistedGlobalStore: any = persist(globalStore, { name: GLOBAL_STORE });

const useGlobalStore = create<GlobalStoreInterface>(persistedGlobalStore);

export default useGlobalStore;
