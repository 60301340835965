import Home from "pages/Home";
import WithSuspense from "components/HOC/withSuspense";
import { lazy } from "react";

const ApplyForLoan = WithSuspense(lazy(() => import("pages/Apply")));
const ApplicationReview = WithSuspense(lazy(() => import("pages/Apply/ApplicationReview")));
const LoadingPage = WithSuspense(lazy(() => import("pages/Apply/LoadingPage")));

export const LoanRoutes = [
  {
    path: "/form/*",
    element: <ApplyForLoan />,
  },
  {
    path: "/apply/:sessionId",
    element: <LoadingPage />,
  },
  {
    path: "/apply/review",
    element: <ApplicationReview />,
  },
  {
    path: "/",
    element: <Home />,
  },
];
